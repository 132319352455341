<template>
    <el-dialog
        :model-value="show"
        :before-close="onClose"
        :close-on-click-modal="false"
        title="新增序列号"
        custom-class="common-edit"
        width="600px"
    >
        <el-form ref="dataFormRef" :label-width="110" :model="dataForm" :rules="dataRules" label-position="left">
            <el-form-item label="序列号：" prop="rule">
                <el-select v-model="dataForm.rule" filterable placeholder="请选择序列号">
                    <el-option
                        v-for="item in ruleOptions"
                        :key="item.id"
                        :label="item.name"
                        :value="item.id"
                    ></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="起始数：" prop="number">
                <el-input-number v-model="dataForm.number" placeholder="请输入起始数"></el-input-number>
            </el-form-item>
            <el-form-item label="生成数量：" prop="count">
                <el-input-number v-model="dataForm.count" placeholder="请输入生成数量" />
            </el-form-item>
        </el-form>
        <template #footer>
            <el-button @click="onClose">取消</el-button>
            <el-button type="primary" @click="onSubmit">提交</el-button>
        </template>
    </el-dialog>
</template>

<script setup>
import { ref, watch } from "vue";
import { ADD_SERIAL_NUMBER, GET_ALL_SERIAL_NUMBER_RULE } from "@/api/serialNumber.js";
import { ElMessage } from "element-plus";
import usePublic from "@/hooks/usePublic";

let { $route, $router, $store } = usePublic();

let props = defineProps({
    show: {
        type: Boolean,
        default: false
    }
});

let emit = defineEmits(["update:show", "update"]);

watch(
    () => props.show,
    (newVal) => {
        if (newVal) getRuleOptions();
    },
    { deep: true }
);

// 规则处理
let ruleOptions = ref([]);

let getRuleOptions = () => {
    GET_ALL_SERIAL_NUMBER_RULE({ type: $route.meta.type, page: 1, limit: 1000000 }).then((res) => {
        console.log(res.data.data);
        ruleOptions.value = res.data.data.list;
    });
};

// 表单数据
let dataForm = ref({
    rule: null,
    number: null,
    count: null
});

let dataFormRef = ref();

let dataRules = {
    rule: [{ required: true, message: "请选择规则！", trigger: "change" }],
    number: [{ required: true, message: "请输入起始数！", trigger: "blur" }],
    count: [{ required: true, message: "请输入生成数量！", trigger: "blur" }]
};

// 关闭
let onClose = () => {
    if (dataFormRef.value) dataFormRef.value.resetFields();
    emit("update");
    emit("update:show", false);
};

// 提交
let onSubmit = () => {
    dataFormRef.value.validate((valid) => {
        if (valid) {
            let nameStr;
            ruleOptions.value.forEach((item) => {
                if (item.id === dataForm.value.rule) {
                    nameStr = item.name + item.name2;
                }
            });

            ADD_SERIAL_NUMBER({
                type: $route.meta.type,
                name: nameStr,
                number: dataForm.value.number,
                count: dataForm.value.count
            }).then((res) => {
                if (res.data.code === 200) {
                    onClose();
                    ElMessage.success("新增成功");
                }
            });
        }
    });
};
</script>
