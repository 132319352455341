import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";

export default function () {
    let $route = useRoute();
    let $router = useRouter();
    let $store = useStore();

    return {
        $route,
        $router,
        $store
    };
}
