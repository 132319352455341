import { httpDelete, httpGet, httpPost, httpPut } from "@/api/request";
import $store from "@/store";

// 规则管理
export function GET_SERIAL_NUMBER_RULE_LIST(params) {
    return httpGet("/serial_number/rule", params, {
        headers: {
            Authorization: `Bearer ${$store.getters.getToken}`
        }
    });
}

export function ADD_SERIAL_NUMBER_RULE(params) {
    return httpPost("/serial_number/rule", params, {
        headers: {
            Authorization: `Bearer ${$store.getters.getToken}`
        }
    });
}

export function EDIT_SERIAL_NUMBER_RULE(params) {
    return httpPut(`/serial_number/rule/${params.id}`, params, {
        headers: {
            Authorization: `Bearer ${$store.getters.getToken}`
        }
    });
}

export function DELETE_SERIAL_NUMBER_RULE(params) {
    return httpDelete(`/serial_number/rule/${params.id}`, params, {
        headers: {
            Authorization: `Bearer ${$store.getters.getToken}`
        }
    });
}

export function BATCH_DELETE_SERIAL_NUMBER_RULE(params) {
    return httpDelete("/serial_number/rule", params, {
        headers: {
            Authorization: `Bearer ${$store.getters.getToken}`
        }
    });
}

export function GET_ALL_SERIAL_NUMBER_RULE(params) {
    return httpGet(`/serial_number/${params.type}/rule`, params, {
        headers: {
            Authorization: `Bearer ${$store.getters.getToken}`
        }
    });
}

// 序列号管理
export function GET_SERIAL_NUMBER_LIST(params) {
    return httpGet(`/serial_number/${params.type}/list`, params, {
        headers: {
            Authorization: `Bearer ${$store.getters.getToken}`
        }
    });
}

export function ADD_SERIAL_NUMBER(params) {
    return httpPost(`/serial_number/${params.type}`, params, {
        headers: {
            Authorization: `Bearer ${$store.getters.getToken}`
        }
    });
}

export function EDIT_SERIAL_NUMBER(params) {
    return httpPut(`/serial_number/${params.id}`, params, {
        headers: {
            Authorization: `Bearer ${$store.getters.getToken}`
        }
    });
}

export function DELETE_SERIAL_NUMBER(params) {
    return httpDelete(`/serial_number/${params.id}`, params, {
        headers: {
            Authorization: `Bearer ${$store.getters.getToken}`
        }
    });
}

export function BATCH_DELETE_SERIAL_NUMBER(params) {
    return httpDelete("/serial_number", params, {
        headers: {
            Authorization: `Bearer ${$store.getters.getToken}`
        }
    });
}
