<template>
    <el-dialog
        :model-value="show"
        :before-close="onClose"
        :close-on-click-modal="false"
        custom-class="common-edit"
        title="编辑序列号"
        width="600px"
    >
        <el-form ref="dataFormRef" :label-width="100" :model="dataForm" :rules="dataRules" label-position="left">
            <el-form-item label="序列号：" prop="name">
                <el-input v-model="dataForm.name" placeholder="请输入序列号" />
            </el-form-item>
        </el-form>
        <template #footer>
            <el-button @click="onClose">取消</el-button>
            <el-button type="primary" @click="onSubmit">提交</el-button>
        </template>
    </el-dialog>
</template>

<script setup>
import { nextTick, ref, watch } from "vue";
import { EDIT_SERIAL_NUMBER } from "@/api/serialNumber.js";
import { ElMessage } from "element-plus";
import usePublic from "@/hooks/usePublic";

let { $route, $router, $store } = usePublic();

let props = defineProps({
    show: {
        type: Boolean,
        default: false
    },
    id: { type: [String, Number] },
    detail: { type: Object }
});

let emit = defineEmits(["update:show", "update"]);

watch(
    () => ({ id: props.id, show: props.show }),
    (newVal) => {
        if (newVal.show && newVal.id) getDetail();
    },
    { deep: true }
);

let getDetail = async () => {
    await nextTick();
    dataForm.value = {
        name: props.detail.name
    };
};

// 表单数据
let dataForm = ref({
    name: null
});

let dataFormRef = ref();

let dataRules = {
    rule: [{ required: true, message: "请输入序列号！", trigger: "blur" }]
};

// 关闭
let onClose = () => {
    if (dataFormRef.value) dataFormRef.value.resetFields();
    emit("update");
    emit("update:show", false);
};

// 提交
let onSubmit = () => {
    dataFormRef.value.validate((valid) => {
        if (valid) {
            EDIT_SERIAL_NUMBER({
                id: props.id,
                ...dataForm.value
            }).then((res) => {
                if (res.data.code === 200) {
                    onClose();
                    ElMessage.success("编辑成功");
                }
            });
        }
    });
};
</script>
